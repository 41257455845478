import Cookies from 'js-cookie'
import { parseQueryParams } from '@/plugins/parse-query-params';

export class IndoleadsPartner {
  constructor({ domain }) {
    this.cpaIdCookieName = 'skb_cpa_transaction_id'
    this.cpaSiteIdCookieName = 'skb_cpa_site_id'
    this.cpaUrlCookieName = 'skb_cpa_url'

    this.cookieDomain = '.' + domain
    const queryParams = parseQueryParams(location.search) || {}

    this.clickIdFromUrl = queryParams.click_id ?? ''
    this.publisherIdFromUrl = queryParams.publisher_id ?? ''

    this.clickIdFromCookie = Cookies.get(this.cpaIdCookieName)
    this.publisherIdFromCookie = Cookies.get(this.cpaSiteIdCookieName)
  }

  saveCookies() {
    if ( this.clickIdFromUrl || this.clickIdFromCookie ) {
      Cookies.set(this.cpaIdCookieName, this.clickIdFromUrl || this.clickIdFromCookie, {
        expires: 45,
        domain: this.cookieDomain,
        path: '/'
      });
    }

    Cookies.set(this.cpaSiteIdCookieName, this.publisherIdFromUrl || this.publisherIdFromCookie, {
      expires: 45,
      domain: this.cookieDomain,
      path: '/'
    });

    Cookies.set(this.cpaUrlCookieName, location.href, {
      expires: 45,
      domain: this.cookieDomain,
      path: '/'
    });
  }

  removeCookies() {
    Cookies.remove(this.cpaIdCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaSiteIdCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaUrlCookieName, {
      domain: this.cookieDomain
    })
  }
}

