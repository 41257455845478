import Cookies from 'js-cookie'
import { parseQueryParams } from '@/plugins/parse-query-params';

export class GdeslonPartner {
  constructor({ domain }) {
    this.expires = 30
    this.cpaIdCookieName = 'skb_cpa_transaction_id'
    this.cpaSiteIdCookieName = 'skb_cpa_site_id'
    this.cpaUrlCookieName = 'skb_cpa_url'

    this.cookieDomain = '.' + domain
    const queryParams = parseQueryParams(location.search) || {}

    this.clickIdFromUrl = queryParams.click_id ?? ''
    this.siteIdFromUrl = queryParams.utm_campaign ?? ''

    this.clickIdFromCookie = Cookies.get(this.cpaIdCookieName)
    this.siteIdFromCookie = Cookies.get(this.cpaSiteIdCookieName)
  }

  saveCookies() {
    Cookies.set(this.cpaIdCookieName, this.clickIdFromUrl || this.clickIdFromCookie, {
      expires: this.expires,
      domain: this.cookieDomain,
      path: '/'
    });

    Cookies.set(this.cpaSiteIdCookieName, this.siteIdFromUrl || this.siteIdFromCookie, {
      expires: this.expires,
      domain: this.cookieDomain,
      path: '/'
    });

    Cookies.set(this.cpaUrlCookieName, location.href, {
      expires: this.expires,
      domain: this.cookieDomain,
      path: '/'
    });
  }

  removeCookies() {
    Cookies.remove(this.cpaIdCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaSiteIdCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaUrlCookieName, {
      domain: this.cookieDomain
    })
  }
}
