const doubleDomains = ['com.ua']

const parseMainDomain = (domain) => {
  let domainParts = domain.split('.')
  let topLevelDomain = domainParts.slice(-2).join('.')

  if (doubleDomains.includes(topLevelDomain)) {
    topLevelDomain = domainParts.slice(-3).join('.')
  }

  return topLevelDomain.replace(/(:)\d*/g, '')
}

export { parseMainDomain }
