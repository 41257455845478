const parseQueryParams = (qs) => {
  qs = qs.startsWith('?') ? qs.replace('?', '') : qs

  const items = qs.split('&')

  return items.reduce(function (data, item) {
    item = item.replace(/\+/g, ' ')
    const splitedParameter = item.split('=')
    const key = decodeURIComponent(splitedParameter[0])
    const value = splitedParameter[1]

    try {
      data[key] = value ? decodeURIComponent(value) : value
    } catch (e) {
      console.error(e)
    }

    return data
  }, {})
}

export { parseQueryParams }
