import Cookies from 'js-cookie'
import { parseQueryParams } from "@/plugins/parse-query-params";

export class GoCpaPartner {
  constructor({ domain }) {
    this.cpaUrlCookieName = 'skb_cpa_url'
    this.cpaLinkIdCookieName = 'skb_cpa_link_id'

    this.cookieDomain = '.' + domain
    const queryParams = parseQueryParams(location.search) || {}

    this.linkIdFromUrl = queryParams.link_id ?? ''

    this.linkIdFromCookie = Cookies.get(this.cpaLinkIdCookieName)
  }

  saveCookies() {
    Cookies.set(this.cpaUrlCookieName, location.href, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    });

    Cookies.set(this.cpaLinkIdCookieName, this.linkIdFromUrl || this.linkIdFromCookie, {
      expires: 30,
      domain: this.cookieDomain,
      path: '/'
    });
  }

  removeCookies() {
    Cookies.remove(this.cpaUrlCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaLinkIdCookieName, {
      domain: this.cookieDomain
    });
  }
}
