import Cookies from 'js-cookie'
import { parseQueryParams } from '@/plugins/parse-query-params';

export class AdvertisePartner {
  constructor({ domain }) {
    this.cpaIdCookieName = 'skb_cpa_transaction_id'
    this.cpaSiteIdCookieName = 'skb_cpa_site_id'
    this.cpaUrlCookieName = 'skb_cpa_url'

    this.cookieDomain = '.' + domain
    const queryParams = parseQueryParams(location.search) || {}

    this.uiIdFromUrl = queryParams.uid ?? ''
    this.siteIdFromUrl = queryParams.site_id ?? ''

    this.uiIdFromCookie = Cookies.get(this.cpaIdCookieName)
    this.siteIdFromCookie = Cookies.get(this.cpaSiteIdCookieName)
  }

  saveCookies() {
    Cookies.set(this.cpaIdCookieName, this.uiIdFromUrl || this.uiIdFromCookie, {
      expires: 45,
      domain: this.cookieDomain,
      path: '/'
    });

    if (this.siteIdFromUrl || this.siteIdFromCookie) {
      Cookies.set(this.cpaSiteIdCookieName, this.siteIdFromUrl || this.siteIdFromCookie, {
        expires: 45,
        domain: this.cookieDomain,
        path: '/'
      });
    }

    Cookies.set(this.cpaUrlCookieName, location.href, {
      expires: 45,
      domain: this.cookieDomain,
      path: '/'
    });
  }

  removeCookies() {
    Cookies.remove(this.cpaIdCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaSiteIdCookieName, {
      domain: this.cookieDomain
    });

    Cookies.remove(this.cpaUrlCookieName, {
      domain: this.cookieDomain
    })
  }
}
