import sbjs from 'sourcebuster'
import Cookies from 'js-cookie'
import { parseMainDomain } from './plugins/parse-domain'
import { CookieSync } from './CookieSync'

// удаляем старую логику формирования utm (для оптимизации кук)
Cookies.remove('utm_source')
Cookies.remove('utm_medium')
Cookies.remove('utm_campaign')
Cookies.remove('utm_content')
Cookies.remove('utm_term')

// фикс для замены &amp; на & в урлах, которые открываются в webview на ios
const url = window.location.href

if (url.includes('&amp%3B') || url.includes('&amp;')) {
  const decodedUrl = url.replace(/&amp%3B/g, '&').replace(/&amp;/g, '&')

  window.history.replaceState({}, '', decodedUrl)
}

// advcake script
;(function (a) {
  var b = a.createElement('script')
  b.async = 1
  b.src = '//code.gjv7fg.ru/'
  a = a.getElementsByTagName('script')[0]
  a.parentNode.insertBefore(b, a)
})(document);

const domain = parseMainDomain(location.hostname)

sbjs.init({
  domain,
  lifetime: 1,
  callback: function callback(sb) {
    new CookieSync(domain).init(sb)
  }
})
